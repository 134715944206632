import { ApolloError } from "@apollo/client";
import type {
  GraphQLError,
  GraphQLErrorExtensions,
  GraphQLErrorOptions,
} from "graphql";
import type { FieldValues, Path } from "react-hook-form";
import { isEqual, merge } from "lodash";

export type FieldErrorsByKey = { [key: string]: string[] };

export type GetFieldErrorsForError = (
  error: GraphQLError,
) => FieldErrorsByKey | undefined | void;

export type PartitionedFieldErrorsTuple = [
  nonFieldErrors?: GraphQLError[],
  fieldErrors?: FieldErrorsByKey,
];

export function makeGetGraphQLFieldErrors(
  /** A function to determine whether a `GraphQLError` is a field error or a
   * non-field error. If the error is a field error, it should return the key to
   * be used in the resulting `fieldErrors` dictionary. */
  getFieldErrorsForError: GetFieldErrorsForError,
) {
  return function getFieldErrors(error: Error): PartitionedFieldErrorsTuple {
    if (!(error instanceof ApolloError)) return [];

    if (!error.graphQLErrors?.length) return [];

    const nonFieldErrors: GraphQLError[] = [];

    const byKey = error.graphQLErrors.reduce<FieldErrorsByKey>(
      (acc, gqlError) => {
        const fieldErrors = getFieldErrorsForError(gqlError);

        if (fieldErrors && Object.keys(fieldErrors).length) {
          merge(acc, fieldErrors);
        } else {
          nonFieldErrors.push(gqlError);
        }

        return acc;
      },
      {},
    );

    if (!Object.keys(byKey).length) return [nonFieldErrors];

    return [nonFieldErrors, byKey];
  };
}

export type ExtendedGraphQLError<Extensions extends GraphQLErrorExtensions> =
  GraphQLError & { extensions: Extensions };

export type ExtendedGraphQLErrorOptions<
  Extensions extends GraphQLErrorExtensions,
> = GraphQLErrorOptions & { extensions: Extensions };

export type ErrorMatcher<Extensions extends GraphQLErrorExtensions> = Partial<
  ExtendedGraphQLErrorOptions<Partial<Extensions>>
>;

export type SimpleFieldErrorMatcher<
  Extensions extends GraphQLErrorExtensions,
  TFieldValues extends FieldValues,
> = {
  field: Path<TFieldValues>;
  matcher: ErrorMatcher<Extensions>;
};

export type FieldsGetterReturn<TFieldValues extends FieldValues> =
  | Partial<Record<Path<TFieldValues>, string[]>>
  | undefined;

export type FieldsGetterErrorMatcher<
  Extensions extends GraphQLErrorExtensions,
  TFieldValues extends FieldValues,
> = {
  getFieldErrors: (error: GraphQLError) => FieldsGetterReturn<TFieldValues>;
  matcher: ErrorMatcher<Extensions>;
};

export type FieldErrorMatcher<
  Extensions extends GraphQLErrorExtensions,
  TFieldValues extends FieldValues,
> =
  | SimpleFieldErrorMatcher<Extensions, TFieldValues>
  | FieldsGetterErrorMatcher<Extensions, TFieldValues>;

function isSimpleFieldErrorMatcher<
  Extensions extends GraphQLErrorExtensions,
  TFieldValues extends FieldValues,
>(
  matcher: FieldErrorMatcher<Extensions, TFieldValues>,
): matcher is SimpleFieldErrorMatcher<Extensions, TFieldValues> {
  return "field" in matcher;
}

type MakeFieldErrorMatcherOptions<Extensions extends GraphQLErrorExtensions> = {
  getIsExtensionsMatch: (
    extensions: GraphQLErrorExtensions,
    extensionsToMatch: Partial<Extensions>,
  ) => boolean;
};

export function makeFieldErrorMatcher<
  Extensions extends GraphQLErrorExtensions,
  TFieldValues extends FieldValues,
>(
  matchers: FieldErrorMatcher<Extensions, TFieldValues>[],
  { getIsExtensionsMatch }: MakeFieldErrorMatcherOptions<Extensions>,
) {
  return function getFieldErrorsForError(error: GraphQLError) {
    return matchers.reduce<FieldErrorsByKey>((acc, fieldMatcher) => {
      const { matcher } = fieldMatcher;

      const isErrorAttrsEqual = (
        ["nodes", "source", "positions", "path", "originalError"] as const
      ).every((attr) => {
        if (!(attr in matcher) || typeof matcher[attr] === undefined) {
          return true;
        }
        return isEqual(matcher[attr], error[attr]);
      });

      if (!isErrorAttrsEqual) return acc;

      if (
        matcher.extensions &&
        !getIsExtensionsMatch(error.extensions, matcher.extensions)
      ) {
        return acc;
      }

      if (isSimpleFieldErrorMatcher(fieldMatcher)) {
        merge(acc, { [fieldMatcher.field]: [error.message] });
      } else {
        const fieldErrors = fieldMatcher.getFieldErrors(error);
        if (fieldErrors) merge(acc, fieldErrors);
      }

      return acc;
    }, {});
  };
}

export type PrefixFields<
  F extends string,
  P extends string | undefined = undefined,
> = P extends undefined ? F : P extends string ? `${P}.${F}` : never;

export type EmptyPrefixedFieldValues<
  F extends string,
  P extends string | undefined = undefined,
> = Record<PrefixFields<F, P>, unknown>;
