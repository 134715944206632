import { ForwardedRef, forwardRef } from "react";
import {
  IconButton,
  IconButtonProps,
  Tooltip,
  TooltipProps,
} from "@chakra-ui/react";
import { OptionalKeys } from "utils/typescriptUtilis";

export type TooltipIconButtonProps = OptionalKeys<
  IconButtonProps,
  "aria-label"
> & {
  tooltipProps?: Partial<TooltipProps>;
  label: React.ReactNode;
};

function TooltipIconButton(
  {
    label,
    tooltipProps,
    "aria-label": ariaLabel,
    ...rest
  }: TooltipIconButtonProps,
  ref: ForwardedRef<HTMLButtonElement>,
) {
  return (
    <Tooltip
      label={label}
      placement="auto-end"
      hasArrow
      isOpen={rest.isDisabled ? false : undefined}
      isDisabled={rest.isDisabled}
      {...tooltipProps}
    >
      <IconButton
        ref={ref}
        borderRadius="lg"
        p={2}
        aria-label={ariaLabel ?? (typeof label === "string" ? label : "")}
        {...rest}
      />
    </Tooltip>
  );
}

export default forwardRef(TooltipIconButton);
