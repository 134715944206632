import { Fragment, ReactNode } from "react";

type Props = {
  parts: ReactNode[];
};

export default function AddWbr({ parts }: Props) {
  return (
    <>
      {parts.map((part, i) => (
        // eslint-disable-next-line react/no-array-index-key
        <Fragment key={`${String(part)}-${i}`}>
          {i > 0 && <wbr />}
          {part}
        </Fragment>
      ))}
    </>
  );
}
