import { ReactNode, useState } from "react";
import { UseToastOptions } from "@chakra-ui/react";
import type { FieldValues, SubmitHandler } from "react-hook-form";
import { ApolloError } from "@apollo/client";
import { flatten, uniq } from "lodash";
import getServerErrorMessage from "utils/apollo/getServerErrorMessage";
import useAsyncCompleteToast from "components/Shared/useAsyncCompleteToast";
import UserError from "utils/UserError";
import getMaybeList from "components/Shared/getMaybeList";
import type {
  FieldErrorsByKey,
  PartitionedFieldErrorsTuple,
} from "./getGraphQLFieldErrors";
import type { OnValidSubmitContext } from "./Form";
import HandledSubmitError from "./HandledSubmitError";
import setFieldErrorsWithDict, {
  ErrorsByFieldName,
} from "./setFieldErrorsWithDict";

export type PerformUpdateHandler<
  TFieldValues extends FieldValues,
  TUpdateResult = unknown,
> = (
  data: Parameters<SubmitHandler<TFieldValues>>[0],
) => Promise<TUpdateResult> | TUpdateResult;

export type UpdateCompleteHandler<TUpdateResult = unknown> = (
  result: Awaited<TUpdateResult> | undefined,
) => void;

export type UseCreateValidSubmitHandlerOptions<TUpdateResult = unknown> = {
  getSuccessToast?: (
    result: Awaited<TUpdateResult> | undefined,
  ) => UseToastOptions;
  isSuccessToasted?: boolean;
  onFieldErrors?: (fieldErrors: FieldErrorsByKey) => void;
  onNonFieldError?: (nonFieldError?: ApolloError) => void;
  onUpdateComplete?: UpdateCompleteHandler<TUpdateResult>;
  processApolloError?: (
    error: ApolloError,
  ) => PartitionedFieldErrorsTuple | undefined | void;
  successMessage?: ReactNode;
};

export type UseCreateValidSubmitHandlerContext = {
  isLoading: boolean;
};

export type UseCreateValidSubmitHandlerReturnTuple<
  TFieldValues extends FieldValues,
  TUpdateResult = unknown,
> = [
  handler: (
    formData: TFieldValues,
    formContext: OnValidSubmitContext<TFieldValues, object>,
  ) => Promise<TUpdateResult>,
  context: UseCreateValidSubmitHandlerContext,
];

export default function useCreateValidSubmitHandler<
  TFieldValues extends FieldValues,
  TUpdateResult,
>(
  onPerformUpdate: PerformUpdateHandler<TFieldValues, TUpdateResult>,
  {
    getSuccessToast,
    isSuccessToasted = true,
    onFieldErrors,
    onNonFieldError,
    onUpdateComplete,
    successMessage = "Save completed successfully",
    processApolloError,
  }: UseCreateValidSubmitHandlerOptions<TUpdateResult> = {},
): UseCreateValidSubmitHandlerReturnTuple<TFieldValues, TUpdateResult> {
  const [isLoading, setIsLoading] = useState(false);

  const { showSuccessToast, showErrorToast } = useAsyncCompleteToast();

  const handleValidSubmit = async (
    formData: TFieldValues,
    { formContext }: OnValidSubmitContext<TFieldValues, object>,
  ) => {
    try {
      setIsLoading(true);
      const result = await onPerformUpdate(formData);
      if (isSuccessToasted) {
        showSuccessToast(successMessage, getSuccessToast?.(result));
      }
      onUpdateComplete?.(result);
      return result;
    } catch (e) {
      if (e instanceof ApolloError) {
        const processedApolloError = processApolloError?.(e);

        if (processedApolloError?.length) {
          const [nonFieldErrors, fieldErrors] = processedApolloError;

          const toastErrors: string[] =
            nonFieldErrors?.map((nfe) => nfe.message) || [];

          if (nonFieldErrors?.length && onNonFieldError) {
            onNonFieldError(
              new ApolloError({ ...e, graphQLErrors: nonFieldErrors }),
            );
          }

          if (fieldErrors) {
            if (onFieldErrors) onFieldErrors(fieldErrors);

            setFieldErrorsWithDict(
              formContext.setError,
              fieldErrors as ErrorsByFieldName<TFieldValues>,
            );

            toastErrors.push(...flatten(Object.values(fieldErrors)));
          }

          if (toastErrors?.length) {
            const uniqToastErrors = uniq(toastErrors);

            // If there are multiple errors and one of them is 'Permission
            // Denied', the permission failure is probably due to another
            // failure with a more descriptive message.
            const cleanedToastErrors =
              uniqToastErrors.length > 1
                ? uniqToastErrors.filter(
                    (err) => err.toLowerCase() !== "permission denied",
                  )
                : uniqToastErrors;

            showErrorToast(null, {
              title:
                cleanedToastErrors.length > 1
                  ? "Some errors occurred during save"
                  : "An error occurred during save",
              description: getMaybeList(cleanedToastErrors),
              duration: 2500 + cleanedToastErrors.length * 1500,
            });
          }
        } else {
          onNonFieldError?.(e);
          showErrorToast(e, {
            title: "An error occurred during save",
            description: e.networkError ? getServerErrorMessage(e) : e.message,
          });
        }
      } else {
        showErrorToast(e);
        if (!(e instanceof UserError)) throw e;
      }
      throw new HandledSubmitError(e);
    } finally {
      setIsLoading(false);
    }
  };

  return [handleValidSubmit, { isLoading }];
}
