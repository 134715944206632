import type {
  ErrorOption,
  FieldPath,
  FieldValues,
  MultipleFieldErrors,
  Path,
  UseFormSetError,
} from "react-hook-form";

type ErrorOptionOrMessage = string | ErrorOption;

export type ErrorsByFieldName<TFieldValues extends FieldValues> = Partial<
  Record<FieldPath<TFieldValues>, ErrorOptionOrMessage[]>
>;

const DEFAULT_TYPE = "server";

function getErrorOption(
  error: ErrorOptionOrMessage,
): Omit<ErrorOption, "types"> {
  if (typeof error === "string") return { type: DEFAULT_TYPE, message: error };
  return error;
}

function getErrorOptionForMultipleErrors(
  errors: ErrorOptionOrMessage[],
): ErrorOption {
  if (errors.length === 1) return getErrorOption(errors[0]);

  const types = errors.reduce<MultipleFieldErrors>((acc, error, idx) => {
    const errorOption = getErrorOption(error);
    const type = errorOption.type || `${DEFAULT_TYPE}${idx > 0 ? idx + 1 : ""}`;
    acc[type] = errorOption.message;
    return acc;
  }, {});

  return { types };
}

export default function setFieldErrorsWithDict<
  TFieldValues extends FieldValues,
>(
  setError: UseFormSetError<TFieldValues>,
  errors: ErrorsByFieldName<TFieldValues>,
  { shouldFocus = true }: { shouldFocus?: boolean } = {},
) {
  const fieldNames = Object.keys(errors) as FieldPath<TFieldValues>[];
  fieldNames.forEach((fieldName) => {
    const fieldErrors = errors[fieldName];

    if (!fieldErrors?.length) return;

    setError(
      fieldName as Path<TFieldValues>,
      getErrorOptionForMultipleErrors(fieldErrors),
      { shouldFocus },
    );
  });
}
