import type { GraphQLErrorExtensions } from "graphql";
import { FieldValues, Path } from "react-hook-form";
import {
  EmptyPrefixedFieldValues,
  FieldErrorMatcher,
  makeFieldErrorMatcher,
  makeGetGraphQLFieldErrors,
  SimpleFieldErrorMatcher,
} from "components/Shared/Forms/getGraphQLFieldErrors";

type SingleErrorExtensions = {
  type?: string;
  field?: string;
};

type ConstraintErrorExtensions = {
  constraintType: "p" | "u" | "c" | "f";
  fields?: string[];
  type: string;
};

export type PostgraphileErrorExtensions =
  | SingleErrorExtensions
  | ConstraintErrorExtensions;

export function isConstraintErrorExtensions(
  extensions: unknown,
): extensions is ConstraintErrorExtensions {
  if (!extensions || typeof extensions !== "object") return false;
  return "constraintType" in extensions;
}

export function isPostgraphileErrorExtensions(
  extensions: unknown,
): extensions is PostgraphileErrorExtensions {
  if (!extensions || typeof extensions !== "object") return false;

  return (
    isConstraintErrorExtensions(extensions) ||
    "type" in extensions ||
    "field" in extensions
  );
}

type PostgraphileErrorMatcherExtensions = SingleErrorExtensions &
  Omit<ConstraintErrorExtensions, "fields">;

type PostgraphileSimpleFieldErrorMatcher<TFieldValues extends FieldValues> =
  SimpleFieldErrorMatcher<PostgraphileErrorMatcherExtensions, TFieldValues>;

type PostgraphileFieldErrorMatcher<TFieldValues extends FieldValues> =
  FieldErrorMatcher<PostgraphileErrorMatcherExtensions, TFieldValues>;

function getIsExtensionsMatch(
  extensions: GraphQLErrorExtensions,
  extensionsToMatch: Partial<PostgraphileErrorMatcherExtensions>,
) {
  if (!isPostgraphileErrorExtensions(extensions)) return false;

  if (extensionsToMatch.type && extensionsToMatch.type !== extensions.type)
    return false;

  if (isConstraintErrorExtensions(extensions)) {
    if (
      extensionsToMatch.field &&
      !extensions.fields?.some((f) => f === extensionsToMatch.field)
    ) {
      return false;
    }

    if (
      extensionsToMatch.constraintType &&
      extensionsToMatch.constraintType !== extensions.constraintType
    )
      return false;

    return true;
  }

  if (extensionsToMatch.field && extensionsToMatch.field !== extensions.field)
    return false;

  return true;
}

export function makePostgraphileErrorMatcher<TFieldValues extends FieldValues>(
  matchers: PostgraphileFieldErrorMatcher<TFieldValues>[],
) {
  return makeFieldErrorMatcher<
    PostgraphileErrorMatcherExtensions,
    TFieldValues
  >(matchers, { getIsExtensionsMatch });
}

export function makePostgraphileErrorProcessor<
  TFieldValues extends FieldValues,
>(matchers: PostgraphileFieldErrorMatcher<TFieldValues>[]) {
  return makeGetGraphQLFieldErrors(makePostgraphileErrorMatcher(matchers));
}

type GetPostgraphileErrorMatchersForFieldsOptions<
  P extends string | undefined = undefined,
> = {
  type?: string;
  fieldNamePrefix?: P;
};

export function getPostgraphileErrorMatchersForFields<
  F extends string,
  P extends string | undefined = undefined,
>(
  fields: readonly F[],
  {
    type,
    fieldNamePrefix,
  }: GetPostgraphileErrorMatchersForFieldsOptions<P> = {},
) {
  return fields.map<
    PostgraphileSimpleFieldErrorMatcher<EmptyPrefixedFieldValues<F, P>>
  >((field) => {
    const extensions: Partial<PostgraphileErrorMatcherExtensions> = { field };

    if (type) {
      extensions.type = type;
    }

    return {
      field: `${fieldNamePrefix ? `${fieldNamePrefix}.` : ""}${field}` as Path<
        EmptyPrefixedFieldValues<F, P>
      >,
      matcher: { extensions },
    };
  });
}
